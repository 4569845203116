import React, { useMemo } from "react";
import { isMobile } from "react-device-detect";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { chartColors } from "../../../../../constants/color";
import { PeriodType } from "../../../../../enums/enum";
import {
  formatNumber,
  generateNetIncomeStackChartData,
} from "../../../../../helpers/dashboardHelper";

import { NetIncomeResponseRecord } from "../../../../../store/dashboard/dashboard.types";
import Icon from "../../../../shared/icon/Icon";

interface NetIncomeGroupBarProps {
  data: Array<NetIncomeResponseRecord>;
  periodType: PeriodType;
}

const renderCustomLabel = (props: any) => {
  const { x, y, width, height, value } = props;

  if (value === 0 || height < 16 || width < 20) {
    return null;
  }

  const textX = x + width / 2; // Centering the text horizontally
  const textY = y + height / 2 + 1.5; // Centering the text vertically

  let formattedText = "0";

  if (value >= 1000000 || value <= -1000000) {
    formattedText = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000 || value <= -1000) {
    formattedText = (value / 1000).toFixed(0) + "K";
  } else {
    formattedText = value.toFixed(0);
  }

  if (width < 25) return null;

  return (
    <text
      x={textX}
      y={textY}
      fill="#fff"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const renderSumLabel = (props: any) => {
  const { x, y, width, value } = props;

  const textX = x + width / 2; // Centering the text horizontally
  const textY = y - 10; // Centering the text vertically

  let formattedText = "0";
  if (value >= 1000000 || value <= -1000000) {
    formattedText = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000 || value <= -1000) {
    formattedText = (value / 1000).toFixed(0) + "K";
  } else {
    formattedText = value.toFixed(0);
  }

  if (width < 25) return null;

  if (formattedText === "0") {
    return null;
  }

  return (
    <text
      x={textX}
      y={textY}
      fill="#000"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;

  if (
    active &&
    payload &&
    payload.length &&
    payload.some((el: { value: number }) => el.value)
  ) {
    return (
      <div
        style={
          isMobile
            ? {
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                padding: "10px",
                position: "absolute",
                left: 0,
                minWidth: "max-content",
              }
            : {
                backgroundColor: "#525252",
                borderRadius: 10,
                color: "#FFFFFF",
                fontSize: "13px",
                padding: 0,
                maxHeight: "250px",
                overflowY: "auto",
                width: "450px",
              }
        }
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            gap: 3,
            alignItems: "center",
            borderBottom: "1px solid #fff",
          }}
        >
          <Icon iconName="clock" fill="white" height={16} width={16} />
          <p className="label">{`Period: ${label}`}</p>
        </div>
        <div style={{ padding: "10px" }}>
          {payload.map((entry: any, index: number) => {
            if (!entry.value) return null;
            return (
              <div
                key={`tooltip-item-${index}`}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    padding: "5px 0",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      background: `${entry.color}`,
                      borderRadius: "5px",
                      display: "inline-block",
                    }}
                  ></span>
                  <p style={{ color: "#fff", fontSize: "12px" }}>
                    {`${entry.name}: `}
                  </p>
                </div>
                <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                  {formatNumber(+entry.value.toFixed(1))} GEL
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

const formatYAxis = (tickItem: number) => {
  if (tickItem >= 1000000 || tickItem <= -1000000) {
    return `${tickItem / 1000000}M`;
  } else if (tickItem >= 10000 || tickItem <= -10000) {
    return `${tickItem / 1000}K`;
  } else {
    return tickItem.toFixed();
  }
};

const NetIncomeGroupBar = ({ data, periodType }: NetIncomeGroupBarProps) => {
  const stackData = useMemo(() => {
    return generateNetIncomeStackChartData(data, periodType);
  }, [data, periodType]);

  if (!data) return null;

  return (
    <ResponsiveContainer width={"100%"} height={isMobile ? 300 : 420}>
      <BarChart data={stackData} style={{ fontFamily: "Helvetica-Neue" }}>
        <XAxis dataKey="period" tick={{ fontSize: 10, color: "#0F0F0F" }} />
        <YAxis
          width={50}
          padding={{ top: 20 }}
          tick={{ fontSize: 12, color: "#0F0F0F" }}
          tickFormatter={formatYAxis}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          content={<CustomTooltip />}
          position={{ y: 120 }}
          trigger="hover"
          isAnimationActive={false}
        />
        {data[0].profits.map((item, index: number) => (
          <Bar
            key={item.id}
            dataKey={item.name}
            stackId={"b"}
            legendType="circle"
            fill={chartColors[index]}
          >
            <LabelList
              fill="#000"
              dataKey={item.name}
              content={renderCustomLabel}
            />

            {index === data[0].profits.length - 1 && (
              <LabelList fill="#000" content={renderSumLabel} />
            )}
          </Bar>
        ))}
        {data[0].expenses.map((item, index: number) => (
          <Bar
            key={item.id}
            dataKey={item.name}
            stackId={"a"}
            legendType="circle"
            fill={chartColors[data[0].profits.length + index]}
          >
            <LabelList
              fill="#000"
              dataKey={item.name}
              content={renderCustomLabel}
            />

            {index === data[0].expenses.length - 1 && (
              <LabelList fill="#000" content={renderSumLabel} />
            )}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default NetIncomeGroupBar;
