import React, { useMemo } from "react";
import { CardTypes, ChartMode, PeriodType } from "../../../../../enums/enum";
import {
  formatNumber,
  generateNetIncomeGridData,
  GridDataItem,
  sumNetIncomeWithName,
} from "../../../../../helpers/dashboardHelper";
import { NetIncomeResponseRecord } from "../../../../../store/dashboard/dashboard.types";
import DashboardChartCard from "../../../../shared/card/Card";
import GroupItemBarChart from "../../groupItemChart/GroupItemBarChart";
import PercentageBarChart from "../../percentageBarChart/PercentageBarChart";
import { ChartContainer, GridContainer } from "./NetIncomeGrid.style";

interface NetIncomeGridProps {
  data: Array<NetIncomeResponseRecord>;
  periodType: PeriodType;
}

const NetIncomeGrid = ({ data, periodType }: NetIncomeGridProps) => {
  const gridData = useMemo(
    () => generateNetIncomeGridData(data, periodType),
    [data, periodType]
  );

  const shouldShow = (item: GridDataItem): boolean => {
    return Boolean(
      item.data &&
        item.data.length > 0 &&
        !item.data.every((el) => el[item.title] === 0)
    );
  };

  return (
    <GridContainer>
      {gridData.map((gridItem, index) => {
        if (shouldShow(gridItem))
          return (
            <DashboardChartCard
              key={index}
              selectedPeriodType={periodType}
              selectedViewType={ChartMode.Grid}
              type={CardTypes.SMALL}
              title={gridItem.label || ""}
              value={formatNumber(sumNetIncomeWithName(data, gridItem.title))}
            >
              <ChartContainer>
                {gridItem.percentage ? (
                  <PercentageBarChart
                    title={gridItem.title}
                    colorIndex={index}
                    data={gridItem.data}
                  />
                ) : (
                  <GroupItemBarChart
                    title={gridItem.title}
                    colorIndex={index}
                    data={gridItem.data}
                  />
                )}
              </ChartContainer>
            </DashboardChartCard>
          );
        return null;
      })}
    </GridContainer>
  );
};

export default NetIncomeGrid;
