import { PeriodType } from "../enums/enum";
import {
  CashFlow,
  CashFlowCategoryType,
  CashFlowDirectionType,
  FinancialInDetailRecord,
  FinancialRecord,
  NetIncomeResponseRecord,
} from "../store/dashboard/dashboard.types";
import { formatDateByPeriod } from "./helper";

export const sumOfGroup = (array: string[]): number => {
  return array.reduce((sum, value) => sum + +parseFloat(value).toFixed(0), 0);
};

export const sumRevenueExpenses = (array: Array<FinancialRecord>): number => {
  return Number(
    array.reduce((sum, item) => sum + item.sumAmount, 0).toFixed(1)
  );
};

export const sumNetIncome = (array: Array<NetIncomeResponseRecord>): number => {
  return Number(
    array
      .reduce((sum, item) => sum + item.totalProfit - item.totalExpense, 0)
      .toFixed(1)
  );
};

export const sumRevenueExpensesWithName = (
  array: Array<FinancialRecord>,
  groupName: string
): number => {
  return Number(
    array
      .reduce(
        (sum, item) => (item.name === groupName ? sum + item.sumAmount : sum),
        0
      )
      .toFixed(1)
  );
};

type OutputData = {
  period: string;
} & {
  [key: string]: number | string;
};

export const generateOrderedStackChartData = (
  data: Array<FinancialRecord>,
  periodType: PeriodType
): OutputData[] => {
  const grouped = data.reduce<Record<string, OutputData>>((acc, item) => {
    const { period, name, sumAmount } = item;
    const formatPeriod = formatDateByPeriod(period, periodType);
    acc[formatPeriod] = acc[formatPeriod] || { period: formatPeriod };
    acc[formatPeriod][name] = sumAmount;
    return acc;
  }, {});

  const orderedGroupNames = Array.from(
    new Set(data.map((item) => item.name))
  ).sort(
    (a, b) =>
      data.find((item) => item.name === a)!.ordering -
      data.find((item) => item.name === b)!.ordering
  );

  return Object.values(grouped).map((entry) => {
    const orderedEntry: OutputData = { period: entry.period };
    orderedGroupNames.forEach((groupName) => {
      if (entry[groupName] !== undefined) {
        orderedEntry[groupName] = entry[groupName];
      }
    });
    return orderedEntry;
  });
};

export const getTableLabels = (
  data: Array<FinancialRecord>
): Array<{ id: number; name: string }> => {
  return Array.from(
    new Map(data.map((item) => [item.name, [item.id, item.ordering]])).entries()
  )
    .sort((a, b) => Number(a[1][1]) - Number(b[1][1]))
    .map(([name, data]) => ({
      name,
      id: +data[0],
    }));
};

export const getUniqueLabels = (data: Array<FinancialRecord>): string[] => {
  return Array.from(
    new Map(data.map((item) => [item.name, item.ordering])).entries()
  )
    .sort((a, b) => a[1] - b[1])
    .map(([name]) => name);
};

export const getUniquePeriods = (
  data: Array<FinancialRecord | NetIncomeResponseRecord>
): string[] => {
  return Array.from(new Set(data.map((item) => item.period)));
};

export const getTreeChartData = (
  data: Array<FinancialRecord>,
  colors: Array<string>
): Array<{ name: string; value: number; netValue: number; color: string }> => {
  const totalSum = data.reduce((total, item) => total + item.sumAmount, 0);

  // Summarize data by mappingGroupName with their total sum
  const summary = data.reduce<
    Record<string, { value: number; ordering: number }>
  >((acc, { name, sumAmount, ordering }) => {
    if (!acc[name]) {
      acc[name] = {
        value: 0,
        ordering: ordering,
      };
    }
    acc[name].value += sumAmount;
    return acc;
  }, {});

  // Convert to array, sort by ordering, and map to final output
  return Object.entries(summary)
    .sort(([, a], [, b]) => a.ordering - b.ordering) // Sort by accountMappingGroupOrdering
    .map(([name, { value }], index) => {
      const percentage = totalSum > 0 ? (value / totalSum) * 100 : 0;
      return {
        name,
        value: parseFloat(percentage.toFixed(2)) || 0,
        netValue: parseFloat(value.toFixed(2)),
        color: colors[index % colors.length], // Handle cases where colors array is shorter
      };
    });
};

export const generateTableData = (
  labels: Array<string>,
  data: Array<FinancialRecord>
): Array<Array<string>> => {
  return labels.map((label) => {
    return data
      .filter((dataItem) => dataItem.name === label)
      .map((dataItem) => dataItem.sumAmount.toFixed(1));
  });
};

export type GridDataItem = {
  id: number | string;
  title: string;
  label?: string;
  percentage?: boolean;
  data: { [key: string]: number | string; period: string }[];
};

export function generateGridData(
  data: Array<FinancialRecord>,
  periodType: PeriodType
): GridDataItem[] {
  const groupedData: Record<
    string,
    { [key: string]: number | string; period: string; id: number | string }[]
  > = {};
  const sortedData = [...data].sort((a, b) => a.ordering - b.ordering);

  sortedData.forEach((item) => {
    const { id, name, sumAmount, period } = item;
    if (!groupedData[name]) {
      groupedData[name] = [];
    }
    groupedData[name].push({
      [name]: sumAmount,
      period: formatDateByPeriod(period, +periodType),
      id,
    });
  });

  return Object.entries(groupedData).map(([title, data]) => ({
    title,
    data,
    id: data[0]?.id || 0,
  }));
}

export const generateNetIncomeLabels = (data: NetIncomeResponseRecord[]) => {
  if (!data || !data.length) return [];
  const { profits, expenses } = data[0];
  return [
    ...profits.map((item) => item.name),
    ...expenses.map((item) => item.name),
  ];
};

// todo need remove hard check
export const sumNetIncomeWithName = (
  array: Array<NetIncomeResponseRecord>,
  groupName: string
): number => {
  const isRevenue = groupName === "Revenue";

  const total = array.reduce((sum, item) => {
    const itemsToSum = isRevenue ? item.profits : item.expenses;
    return (
      sum +
      itemsToSum.reduce((innerSum, innerItem) => innerSum + innerItem.amount, 0)
    );
  }, 0);

  return Math.round(total * 10) / 10;
};

export const generateNetIncomeTableData = (
  labels: Array<string>,
  data: Array<NetIncomeResponseRecord>
): Array<Array<string>> => {
  return labels.map((label) => {
    return data
      .flatMap((record) => [...record.expenses, ...record.profits])
      .filter((item) => item.name === label)
      .map((item) => item.amount.toFixed(1));
  });
};

export function generateNetIncomeGridData(
  data: Array<NetIncomeResponseRecord>,
  periodType: PeriodType
): GridDataItem[] {
  const revenueData: Array<{
    id: number;
    period: string;
    NetIncome: number;
  }> = [];
  const expensesData: Array<{
    id: number;
    period: string;
    percentage: number;
  }> = [];

  data.forEach((item, index) => {
    const income = item.profits.reduce(
      (sum, profitItem) => sum + profitItem.amount,
      0
    );
    const outcome = item.expenses.reduce(
      (sum, profitItem) => sum + profitItem.amount,
      0
    );

    const percentage = ((income - outcome) / income) * 100;

    revenueData.push({
      id: index,
      period: formatDateByPeriod(item.period, +periodType),
      NetIncome: income - outcome,
    });

    expensesData.push({
      id: index,
      period: formatDateByPeriod(item.period, +periodType),
      percentage:
        typeof percentage !== "number" ||
        isNaN(percentage) ||
        percentage === Infinity ||
        percentage === -Infinity
          ? 0
          : percentage,
    });
  });

  return [
    { data: revenueData, label: "Net Income", title: "NetIncome", id: 1 },
    {
      data: expensesData,
      percentage: true,
      label: "Net Income Percentage",
      title: "percentage",
      id: 2,
    },
  ];
}

export const generateNetIncomeStackChartData = (
  data: NetIncomeResponseRecord[],
  periodType: PeriodType
) => {
  return data.map((item) => {
    const data: { [key: string]: number | string } = {
      period: formatDateByPeriod(item.period, periodType),
    };

    [...item.expenses, ...item.profits].forEach((entry) => {
      data[entry.name] = entry.amount;
    });

    return data;
  });
};

// old version
export interface BarChartDataRecord {
  data: Array<{ label: string; [key: string]: number | string }>;
  labels: Array<string>;
}

// CashFlow
export const generateCashFlowGroupedChartDataForGroups = (
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  const groupedData: { [key: string]: any } = {};

  data.forEach((cashFlowItem) => {
    const { period, cashFlowCategory, cashFlowDirection, amount } =
      cashFlowItem;
    if (!groupedData[formatDateByPeriod(period, periodType)])
      groupedData[formatDateByPeriod(period, periodType)] = {
        period: formatDateByPeriod(period, periodType),
      };

    const directionKey =
      cashFlowDirection === CashFlowDirectionType.Received
        ? "Received"
        : "Paid";
    const categoryKey = CashFlowCategoryType[cashFlowCategory];

    groupedData[formatDateByPeriod(period, periodType)][
      `${directionKey}-${categoryKey}`
    ] = amount;
  });
  return Object.values(groupedData);
};

// cashFlow
export const generateCashFlowOutcome = (
  keys: Array<string>,
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          ["Net Cash Flow"]:
            item.cashFlowDirection === CashFlowDirectionType.Received
              ? item.amount
              : -item.amount,
        };
      } else {
        barData[item.period]["Net Cash Flow"] =
          item.cashFlowDirection === CashFlowDirectionType.Received
            ? barData[item.period]["Net Cash Flow"] + item.amount
            : barData[item.period]["Net Cash Flow"] - item.amount;
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const formatNumber = (sum: number) =>
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getMonthlyPeriods = (
  fromDate: string,
  toDate: string
): string[] => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const result: string[] = [];

  const current = new Date(start.getFullYear(), start.getMonth(), 1);

  while (current <= end) {
    const month = current.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    result.push(`${current.getFullYear()}-${formattedMonth}`);
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

export const processAccountData = (
  data: Array<FinancialInDetailRecord>
): Record<string, number> => {
  // Sum totals for each accountId
  const totals: Record<string, number> = {};
  data.forEach((entry) => {
    if (!totals[entry.accountId]) {
      totals[entry.accountId] = 0;
    }
    totals[entry.accountId] += entry.total;
  });

  // Rank accountIds based on their totals
  const sortedAccountIds = Object.entries(totals)
    .sort(([, totalA], [, totalB]) => totalB - totalA)
    .map(([accountId]) => accountId);

  // Assign ranks
  const ranking: Record<string, number> = {};
  sortedAccountIds.forEach((accountId, index) => {
    ranking[accountId] = index + 1;
  });

  return ranking;
};
