import { ChartGroupTypes, ChartType, PeriodType } from "../../enums/enum";

export type FinancialGroupsRequest = {
  periodType: PeriodType;
  fromDate: string;
  toDate: string;
  companyBranchIds?: Array<number | string>;
  companyBusinessLineIds?: Array<number | string>;
  chartType: ChartType;
  accountMappingGroupIds?: Array<number | string>;
};

export type FinancialGroupByIdRequest = {
  periodType: PeriodType;
  fromDate: string;
  toDate: string;
  chartType: ChartType;
  accountMappingGroupId: number | null;
  companyBusinessLineIds?: Array<number | string>;
  companyBranchIds?: Array<number | string>;
  accountIds?: Array<number | string>;
};

export type FinancialInDetailRequest = {
  periodType: PeriodType;
  fromDate: string;
  toDate: string;
  chartType: ChartType;
  accountId: string;
};

export type FinancialGroupsResponse = {
  data: Array<FinancialRecord>;
  filters?: Array<{ id: string; name: string }>;
  lastUpdateTime: string;
};

export type FinancialRecord = {
  id: number | string;
  name: string;
  ordering: number;
  period: string;
  sumAmount: number;
};

export type FinancialInDetailResponse = {
  data: Array<FinancialInDetailRecord>;
  lastUpdateTime: string;
};

export type FinancialInDetailRecord = {
  accountId: number;
  accountName: string;
  period: string;
  total: number;
};

export type NetIncomeResponse = {
  data: Array<NetIncomeResponseRecord>;
  filters?: Array<{ id: string; name: string }>;
  lastUpdateTime: string;
};

export type NetIncomeAccountingItem = {
  amount: number;
  id: number;
  name: string;
  ordering: number;
};

export type NetIncomeResponseRecord = {
  period: string;
  expenses: Array<NetIncomeAccountingItem>;
  profits: Array<NetIncomeAccountingItem>;
  totalExpense: number;
  totalProfit: number;
};

export type ProfitLostData = {
  period: string;
  ["Total Income"]: number;
  ["Total Expense"]: number;
};

export type CashFlow = {
  period: string;
  cashFlowCategory: CashFlowCategoryType;
  cashFlowDirection: CashFlowDirectionType;
  amount: number;
};

export enum CashFlowDirectionType {
  Received = 1,
  Paid = 2,
}

export enum CashFlowCategoryType {
  None = 0,
  Operating = 1,
  Investment = 2,
  Financing = 3,
}
export type ReportalReportItem = {
  amount: number;
  rePortalMappingId: number;
  year: number;
};

export type Filter = {
  periodType: PeriodType;
  fromDate: string;
  toDate: string;
};

export type FrontSideFilter = {
  group: ChartGroupTypes;
  lines: Array<{ value: string; label: string }>;
  branches: Array<{ value: string; label: string }>;
  groupNames: Array<{ value: string; label: string }>;
};

export type PeriodFilterType = {
  fromDate: string;
  toDate: string;
};

export type StreamFilterType = {
  fromDate: string;
  toDate: string;
};

export type MarketDataInfo = {
  name: string;
  amount: number;
  isConsolidated: boolean;
  isSum: boolean;
};

export type MarketData = {
  identificationCode: number | null;
  name: string | null;
  companyCategoryId: number | null;
  companyFormId: number | null;
  data: Record<number, Array<MarketDataInfo>> | null | undefined;
};

export type RevenueFilter = {
  dateRange: { type: number; range: Array<string> };
  period: PeriodType;
  groups: Array<string | number>;
  branches: Array<string | number>;
  businessLines: Array<string | number>;
};

export type ExpensesFilter = {
  dateRange: { type: number; range: Array<string> };
  period: PeriodType;
  groups: Array<string | number>;
  branches: Array<string | number>;
  businessLines: Array<string | number>;
};
