import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { chartColors } from "../../../../../constants/color";
import { generateNetIncomeLabels } from "../../../../../helpers/dashboardHelper";
import { NetIncomeResponseRecord } from "../../../../../store/dashboard/dashboard.types";

import {
  ColorBox,
  ListItem,
  ListItemWrapper,
  Wrapper,
} from "./NetIncomeBarChartLabels.style";

interface IProps {
  data: NetIncomeResponseRecord[];
}
const NetIncomeBarChartLabels = ({ data }: IProps) => {
  const { t } = useTranslation();

  const listLabels = useMemo(() => {
    return generateNetIncomeLabels(data);
  }, [data]);

  return (
    <Wrapper>
      {listLabels.map((itemName, index) => (
        <ListItemWrapper key={index}>
          <ColorBox color={chartColors[index]} />
          <ListItem>{t(itemName)}</ListItem>
        </ListItemWrapper>
      ))}
    </Wrapper>
  );
};

export default NetIncomeBarChartLabels;
