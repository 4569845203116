import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getNetIncomeInfo } from "../../../../store/dashboard/dashboardAction";
import {
  Wrapper,
  LastUpdated,
  Header,
  Title,
  TitleWrapper,
  UpdateDateSkeleton,
  GroupBarChartContainer,
  GroupDetailChartWrapper,
  BarChartTitleContainer,
  SkeletonWrapper,
} from "./NetIncome.style";

import {
  dashboardDateFormat,
  generateGridChartModes,
} from "../../../../helpers/helper";
import {
  CardTypes,
  ChartMode,
  PeriodType,
  RangeType,
} from "../../../../enums/enum";

import { useTranslation } from "react-i18next";

import Filters from "../../../../components/shared/dashboard/filters/Filters";
import EmptyDashboard from "../../../../components/shared/emptyDashboard/EmptyDashboard";
import DashboardChartCard from "../../../../components/shared/card/Card";

import ChartTypeSwitcher from "../../../../components/shared/dashboard/chartTypeSwitcher/ChartTypeSwitcher";

import { getRange365Days } from "../../../../helpers/dateHelper";
import BigCardSkeleton from "../../../../components/shared/dashboard/skeletons/bigCardSkeleton/BigCardSkeleton";
import CardGroupsSkeleton from "../../../../components/shared/dashboard/skeletons/cardGroupsSkeleton/CardGroupsSkeleton";
import moment from "moment";
import {
  formatNumber,
  sumNetIncome,
} from "../../../../helpers/dashboardHelper";
import NetIncomeGroupBar from "../../../../components/desktop/charts/netIncome/netIncomeGroupBar/NetIncomeGroupBar";
import NetIncomeGrid from "../../../../components/desktop/charts/netIncome/netIncomeGrid/NetIncomeGrid";
import NetIncomeTable from "../../../../components/desktop/charts/netIncome/netIncomeTable/NetIncomeTable";
import NetIncomeBarChartLabels from "../../../../components/desktop/charts/netIncome/netIncomeBarChartLabels/NetIncomeBarChartLabels";

const initialFilterData = {
  date: {
    type: RangeType.Past365Days,
    range: getRange365Days(),
  },
  period: PeriodType.Month,
  groups: [],
  branches: [],
  businessLines: [],
};

const NetIncome = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowClearAll, setIsShowClearAll] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState(
    initialFilterData.date
  );
  const [selectedPeriodType, setSelectedPeriodType] = useState(
    initialFilterData.period
  );
  const [viewMode, setViewMode] = useState<ChartMode>(ChartMode.Grid);

  const netIncome = useAppSelector((state) => state.dashboard.netIncomeData);

  const netIncomeLoading = useAppSelector(
    (state) => state.dashboard.netIncomeLoading
  );

  const hasNoData = useMemo(() => {
    return netIncome.data.every(
      (item) => item.totalExpense === 0 && item.totalProfit === 0
    );
  }, [netIncome]);

  const totalSum = useMemo(() => {
    return formatNumber(sumNetIncome(netIncome.data));
  }, [netIncome]);

  useEffect(() => {
    dispatch(
      getNetIncomeInfo({
        periodType: PeriodType.Month,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const handleClearAll = () => {
    dispatch(
      getNetIncomeInfo({
        periodType: PeriodType.Month,
        fromDate: moment(initialFilterData.date.range[0]).format("YYYY-MM-DD"),
        toDate: moment(initialFilterData.date.range[1]).format("YYYY-MM-DD"),
      })
    );
  };

  // const handleDownload = () => {
  //   const data: FinancialGroupsRequest = {
  //     periodType: selectedPeriodType,
  //     fromDate: moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
  //     toDate: moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
  //     chartType: selectedChartType,
  //     companyBranchIds: selectedBranches,
  //     companyBusinessLineIds: selectedBusinessLine,
  //     accountMappingGroupIds: selectedGroups,
  //   };
  //   dispatch(getRevenueGroupsExcel(data))
  //     .then((action: ActionType) => {
  //       if (action.error) {
  //         console.error("Download failed:", action.error);
  //       } else if (action?.payload) {
  //         downloadFile(action?.payload);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Unexpected error during download:", error);
  //     });
  // };

  const handleFilter = ({
    periodType,
    dateRange,
    dateType,
  }: {
    periodType?: PeriodType;
    dateRange?: Array<string>;
    dateType?: RangeType;
  }) => {
    let data = {
      periodType: periodType || selectedPeriodType,
      fromDate: dateRange?.length
        ? dateRange[0]
        : moment(selectedDateRange.range[0]).format("YYYY-MM-DD"),
      toDate: dateRange?.length
        ? dateRange[1]
        : moment(selectedDateRange.range[1]).format("YYYY-MM-DD"),
    };

    dispatch(getNetIncomeInfo(data));
  };

  if (isMobile) return <div>under construction</div>;

  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <Title>{t("ProfitLoss.Title")}</Title>
          {netIncome.lastUpdateTime ? (
            <LastUpdated>
              {t("ProfitLoss.DateUpdatedTitle")}:{" "}
              {dashboardDateFormat(netIncome.lastUpdateTime)}
            </LastUpdated>
          ) : (
            <UpdateDateSkeleton />
          )}
        </TitleWrapper>

        <Filters
          isShowClearAll={isShowClearAll}
          groupOptions={[]}
          selectedDateRange={selectedDateRange}
          selectedBranches={[]}
          selectedGroups={[]}
          isLoading={false}
          handleDateRange={(dateData) => {
            setIsShowClearAll(true);
            setSelectedDateRange(dateData);
            handleFilter({
              dateRange: dateData.range,
              dateType: dateData.type,
            });
          }}
          handleClearAll={() => {
            setIsShowClearAll(false);
            setSelectedDateRange(initialFilterData.date);
            handleClearAll();
          }}
          // handleDownload={handleDownload}
        />
      </Header>

      {netIncomeLoading === "pending" ? (
        <SkeletonWrapper>
          <BigCardSkeleton />
          <CardGroupsSkeleton />
        </SkeletonWrapper>
      ) : (
        <>
          {hasNoData ? (
            <EmptyDashboard />
          ) : (
            <>
              <DashboardChartCard
                selectedPeriodType={selectedPeriodType}
                selectedViewType={ChartMode.Stacked}
                type={CardTypes.LARGE}
                title={t("ProfitLoss.DashboardCardTitle")}
                value={totalSum}
                handlePeriodType={(periodType) => {
                  setSelectedPeriodType(periodType);
                  handleFilter({ periodType });
                }}
              >
                <NetIncomeBarChartLabels data={netIncome.data} />
                <GroupBarChartContainer>
                  <NetIncomeGroupBar
                    data={netIncome.data}
                    periodType={selectedPeriodType}
                  />
                </GroupBarChartContainer>
              </DashboardChartCard>
              <GroupDetailChartWrapper>
                <BarChartTitleContainer>
                  <Title>{t("ProfitLoss.GroupChartTitle")}</Title>
                  <ChartTypeSwitcher
                    viewOptions={generateGridChartModes()}
                    selectedViewMode={viewMode}
                    handleViewMode={setViewMode}
                  />
                </BarChartTitleContainer>
                {viewMode === ChartMode.Grid && (
                  <NetIncomeGrid
                    periodType={selectedPeriodType}
                    data={netIncome.data}
                  />
                )}
                {viewMode === ChartMode.Table && (
                  <NetIncomeTable
                    periodType={selectedPeriodType}
                    data={netIncome.data}
                  />
                )}
              </GroupDetailChartWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default NetIncome;
