import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { chartColors } from "../../../../../constants/color";
import { PeriodType } from "../../../../../enums/enum";
import {
  formatNumber,
  generateNetIncomeLabels,
  generateNetIncomeTableData,
  getUniquePeriods,
  sumOfGroup,
} from "../../../../../helpers/dashboardHelper";
import { formatDateByPeriod } from "../../../../../helpers/helper";
import { NetIncomeResponseRecord } from "../../../../../store/dashboard/dashboard.types";

import {
  Wrapper,
  ColorBox,
  TableHeaderContainer,
  LeftSide,
  RightSide,
  SideTableTitleWrapper,
  Table,
  TableHeader,
  TableHeaderItem,
  TableList,
  TableListItem,
  TableHeaderTotalContainer,
  TableTotalListItem,
} from "./NetIncomeTable.style";

interface IProps {
  data: Array<NetIncomeResponseRecord>;
  periodType: PeriodType;
}

const NetIncomeTable = ({ data, periodType }: IProps) => {
  const { t } = useTranslation();
  const [hoverIndex, setHoverIndex] = useState(-1);

  const periods = getUniquePeriods(data);

  const uniqLabels = useMemo(() => {
    return generateNetIncomeLabels(data);
  }, [data]);

  const tableLabels = useMemo(() => {
    return uniqLabels.map((label) => ({ name: label }));
  }, [uniqLabels]);

  const tableList = useMemo(() => {
    return generateNetIncomeTableData(uniqLabels, data);
  }, [data, uniqLabels]);

  const total = useMemo(() => {
    let sum = 0;
    let revenue = 0;
    let expenses = 0;
    tableList.forEach((group, groupIndex) => {
      if (groupIndex === 0) {
        group.forEach((item) => {
          const value = parseFloat(item);
          if (!isNaN(value)) {
            revenue += value;
          }
        });
      } else {
        group.forEach((item) => {
          const value = parseFloat(item);
          if (!isNaN(value)) {
            expenses += value;
          }
        });
      }
    });

    sum = revenue - expenses;
    return formatNumber(Number(sum.toFixed(1)));
  }, [tableList]);

  const calculateItemTotal = (index: number) => {
    let itemSum = 0;
    const revenue = parseFloat(tableList[0][index]);
    const expense = parseFloat(tableList[1][index]);
    if (!isNaN(revenue) && !isNaN(expense)) {
      itemSum = revenue - expense;
    }

    return formatNumber(Number(itemSum.toFixed(1)));
  };

  return (
    <Wrapper>
      <Table>
        <LeftSide>
          <SideTableTitleWrapper isGrey>
            <ColorBox size="small" />
            <TableHeaderItem>{t("TableChart.GroupsTitle")}</TableHeaderItem>
          </SideTableTitleWrapper>
          <SideTableTitleWrapper>
            <ColorBox />
            <TableHeaderItem isListItem>
              {t("TableChart.TotalItemTitle")}
            </TableHeaderItem>
          </SideTableTitleWrapper>
          {tableLabels.map((label, index) => (
            <SideTableTitleWrapper
              key={index}
              hasHover={hoverIndex === index}
              onMouseEnter={() => {
                setHoverIndex(index);
              }}
              onMouseLeave={() => {
                setHoverIndex(-1);
              }}
            >
              <ColorBox color={chartColors[index]} />
              <TableHeaderItem isListItem isBold>
                {label.name}
              </TableHeaderItem>
            </SideTableTitleWrapper>
          ))}
        </LeftSide>
        <RightSide>
          <TableHeader>
            <TableHeaderTotalContainer>
              {t("TableChart.TotalIncomeTitle")}
            </TableHeaderTotalContainer>
            {periods.map((date, index) => (
              <TableHeaderContainer key={index}>
                {formatDateByPeriod(date, periodType)}
              </TableHeaderContainer>
            ))}
          </TableHeader>

          <TableList hasHover={false}>
            <TableTotalListItem isBold>{total}</TableTotalListItem>
            {tableList[0]?.map((_, itemIndex) => (
              <TableListItem isBold key={itemIndex}>
                {calculateItemTotal(itemIndex)}
              </TableListItem>
            ))}
          </TableList>

          {tableList.map((group, groupIndex) => (
            <TableList
              key={groupIndex}
              hasHover={groupIndex === hoverIndex}
              onMouseEnter={() => {
                setHoverIndex(groupIndex);
              }}
              onMouseLeave={() => {
                setHoverIndex(-1);
              }}
            >
              <TableTotalListItem isBold>
                {formatNumber(sumOfGroup(group))}
              </TableTotalListItem>
              {group.map((value, itemIndex) => (
                <TableListItem key={itemIndex}>
                  {formatNumber(+value)}
                </TableListItem>
              ))}
            </TableList>
          ))}
        </RightSide>
      </Table>
    </Wrapper>
  );
};

export default NetIncomeTable;
