import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";

import {
  ArrowWrapper,
  SideBarNavigationItemsWrapper,
  StraightBorder,
  Wrapper,
} from "./SideBar.style";
import SideBarNavigationItem from "./sideBarNavigationItem/SideBarNavigationItem";

import Icon from "../../shared/icon/Icon";
import { getCompanyInfo } from "../../../store/company/companyAction";
import { checkPermissionForRoute } from "../../../helpers/helper";
import { PageURL } from "../../../constants/router";
import SelectCompany from "../selectCompany/SelectCompany";
import SideBarNavigationWithDropdown from "./sideBarNavigationWithDropdown/SideBarNavigationWithDropdown";

const SideBar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [opened, setIsOpened] = useState(true);

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, [dispatch]);

  const user = useAppSelector((state) => state.user.user);

  const checkPermission = (path: string) => {
    if (user) {
      return checkPermissionForRoute(path, PageURL, user.permissions);
    }
    return false;
  };

  const handleClick = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <Wrapper active={opened}>
      <ArrowWrapper className={opened ? "rotate" : ""} onClick={handleClick}>
        <Icon iconName="arrowDown" />
      </ArrowWrapper>
      <SideBarNavigationItemsWrapper>
        <SelectCompany active={opened} setActive={setIsOpened} />
        <SideBarNavigationWithDropdown
          title={t("SideBar.ProfitLoss")}
          icon="profitLoss"
          setOpen={setIsOpened}
          active={opened}
          dropdownItems={[
            {
              labelKey: "SideBar.Revenue",
              path: PageURL.Revenue.path,
              hasPermission: checkPermission(PageURL.Revenue.path),
            },
            {
              labelKey: "SideBar.Expenses",
              path: PageURL.Expenses.path,
              hasPermission: checkPermission(PageURL.Expenses.path),
            },
            {
              labelKey: "SideBar.NetIncome",
              path: PageURL.ProfitLoss.path,
              hasPermission: checkPermission(PageURL.ProfitLoss.path),
            },
          ]}
        />

        {checkPermission(PageURL.CashFlow.path) && (
          <SideBarNavigationItem
            active={opened}
            path={PageURL.CashFlow.path}
            iconName={"cashFlow"}
            pageName={t("SideBar.CashFlow")}
          />
        )}
        {checkPermission(PageURL.Reportal.path) && (
          <SideBarNavigationItem
            active={opened}
            path={PageURL.Reportal.path}
            iconName={"reportal"}
            pageName={t("SideBar.Reportal")}
          />
        )}
        {checkPermission(PageURL.RevenueServices.path) && (
          <SideBarNavigationItem
            active={opened}
            path={PageURL.RevenueServices.path}
            iconName={"rsGe"}
            pageName={t("SideBar.RevenueService")}
          />
        )}
        {checkPermission(PageURL.Reports.path) && (
          <SideBarNavigationItem
            active={opened}
            path={PageURL.Reports.path}
            iconName={"report"}
            pageName={t("SideBar.Reports")}
          />
        )}
        {checkPermission(PageURL.Market.path) && (
          <SideBarNavigationItem
            active={opened}
            path={PageURL.Market.path}
            iconName={"market"}
            pageName={t("SideBar.Market")}
          />
        )}
        <SideBarNavigationWithDropdown
          icon="folder"
          title={t("SideBar.Data")}
          setOpen={setIsOpened}
          active={opened}
          dropdownItems={[
            {
              labelKey: "SideBar.ImportData",
              path: PageURL.ImportData.path,
              hasPermission: checkPermission(PageURL.ImportData.path),
            },
            {
              labelKey: "SideBar.Mapping",
              path: PageURL.Mapping.path,
              hasPermission: checkPermission(PageURL.Mapping.path),
            },
          ]}
        />
      </SideBarNavigationItemsWrapper>
      <div>
        <StraightBorder />
        <SideBarNavigationItem
          active={opened}
          path={PageURL.FAQ.path}
          iconName={"help"}
          pageName={t("SideBar.HelpCenter")}
        />
      </div>
    </Wrapper>
  );
};

export default SideBar;
